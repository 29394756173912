/**
 * Perfil de usuario.
 * No confundir con edicion de accounts (UsersForm), a la que solo tienen acceso
 * accounts con el role "write" o "all".
 */
window.UserForm = Backbone.View.extend({
    tagName: 'div',
    template: JST['user-form'],

    events: {

          'click #btn-save': 'save',
          'click #btn-cancel': 'cancel',
        'keyup input': Backbone.binding
    },
    initialize: function() {
        if (!this.model) {
            this.model = new Users();
        }
    },
    render: function() {
      /*  traky.setHGroup('Tu perfil');
        var data = this.model.toJSON();
        this.$el.html(this.template(data));
        return this;
*/


        var data = this.model.toJSON();
        data.isNew = this.model.isNew();

        var mode = 'Agregar';
        if (!data.isNew) {mode = 'Editar';}
        traky.setHGroup(mode + ' Usuario');

        this.$el.html(this.template(data));

      //  this.renderRoles();

        return this;
    },


    cargarDatos: function(){
     
            var name=this.$el.find('input[data-binding="name"]').val();
          //  this.model.set("name", this.$el.find('input[data-binding="name"]').val());
           this.model.set("name",name);
          //  this.model.set("surname",this.$el.find('input[data-binding="surname"]').val());
            var username=this.$el.find('input[data-binding="username"]').val();
             this.model.set("username",username);
          //  this.model.set("email", this.$el.find('input[data-binding="email"]').val());
          var email=this.$el.find('input[data-binding="email"]').val();
           this.model.set("email",email);
            //this.model.set("apikey",this.$el.find('input[data-binding="apiley"]').val());
            var pass=this.$el.find('input[data-binding="password"]').val();
             this.model.set("password",pass);
            var per=this.$el.find('select[data-binding="permiso"]').val();
               this.model.set("rol",per);

               if (per==="administrador"){
            this.model.set('_roles', traky.rol_admin);
               }else{
                 this.model.set('_roles', traky.rol_usuario);
               }
    },
    save: function() {
       

        this.cargarDatos();
     var str="{name:"+ "'"+this.model.get("name")+"'" +", username:"+"'"+this.model.get("username")+"'"+
      " ,email:"+"'"+this.model.get("email")+"'"+", password:"+"'"+this.model.get("password")+"'"+
      ",rol:"+"'"+this.model.get("rol")+this.model.get("_roles")+"}"
       



       //this.model.save({username:'adela',name:'adela',email:'adela.tena@gmail.com',password:'12345',_roles:json}, {


          this.model.save(null, {

            success: function() {
                notify.success('Tu perfil se ha guardado', true);
          
                    app.navigate('admin/users', true);
            },
            error: function (status) {
               
                notify.error('Ha ocurrido un error, inténtalo de nuevo. (code ' + status.status + ')', true);
            }

        });

      },
      cancel: function() {
     
          bootbox.confirm('¿Seguro que quieres cancelar la edición?', function() {
              app.navigate('admin/users', true);
          });
      },
});
