$(document).on('traky_init', function() {
   
    traky.isDMY = function(date) {
        var regex = /^([0-9]{1,2})[\.\-\/]([0-9]{1,2})[\.\-\/]([0-9]{4,4})/gi;
        return regex.test(date);
    };

    traky.datepicker = function(data, id) {
        $('#' + id).datepicker()
            .on('changeDate', function(ev) {
                $('#' + id).val(traky.dateFormatted(ev.date));
                $('#' + id).data('status', 'modified');
            })
            .on('hide', function(ev) {
                //$('#' + id).val(traky.dateFormatted());
                if ($('#' + id).data('status') !== 'modified') {
                    if ($('#' + id).val())
                        $('#' + id).val(traky.dateFormatted($('#' + id).data('date')));
                } else {
                    $('#' + id).val(traky.dateFormatted(ev.date));
                }
            });

        if (data && data.mode !== 'add') {
            if (data.type === 'event') {
                $('#published').data('date', data.published);
                $('#published').val(traky.dateFormatted(data.published));

                if (data.start_date) {
                    $('#startDate').data('date', data.start_date);
                    $('#startDate').val(traky.dateFormatted(data.start_date));
                }
                if (data.stop_date) {
                    $('#endDate').data('date', data.stop_date);
                    $('#endDate').val(traky.dateFormatted(data.stop_date));
                }
            } else if (data.type && data.type.substring(0, 4) === 'page') {
                $('#published').data('date', data.published);
                $('#published').val(traky.dateFormatted(data.published));
            }
        }
    };

    /**
     * Le pasas una fecha y te la devuelve en formato: dia-mes-año hora:minutos
     */
    traky.dateTimeFormatted = function(date) {
        var newDate = date ? new Date(date) : new Date();
        var d = newDate.getDate();
        var m = newDate.getMonth();
        m += 1;
        var y = newDate.getFullYear();
        var h = newDate.getHours();
        var min = newDate.getMinutes(); //alert(h);
        var dateFormat = traky.correctFormat(d) + "-" + traky.correctFormat(m) + "-" + y + " " +
         traky.correctFormat(h) + ":" + traky.correctFormat(min);
        return dateFormat;
    };

    traky.dateFormatted = function(date) {
        var newDate = date ? new Date(date) : new Date();
        var d = newDate.getDate();
        var m = newDate.getMonth();
        m += 1;
        var y = newDate.getFullYear();

        var dateFormat = traky.correctFormat(d) + "-" +
        traky.correctFormat(m) + "-" + y;
        return dateFormat;
    };

    /**
     * Le pasas una fecha string y te la devuelve en formato para poderla insertar en MongoDB
     */
    traky.strDateToMongo = function(d) {
        if (d) {
            var DD = d.substring(0, 2);
            var MM = d.substring(3, 5);
            var YYYY = d.substring(6, 10);
            var hh = d.substring(11, 13);
            var mm = d.substring(14, 16);
            return YYYY + '-' + MM + '-' + DD + ' ' + hh + ':' + mm;
        }
        return null;
    };

    traky.dateToMongo = function(d) {



        if (d) {
          var str_d=this.dateTimeFormatted(d)
          var DD = str_d.substring(0, 2);
            var MM = str_d.substring(3, 5);
            var YYYY = str_d.substring(6, 10);
            var hh = str_d.substring(11, 13);
            var mm = str_d.substring(14, 16);
            return YYYY + '-' + MM + '-' + DD + ' ' + hh + ':' + mm;
        }
        return null;
    };
    /**
     * Formato de dos dígitos para horas i minutos
     */

    traky.correctFormat = function(el) {
        return el <= 9 ? '0' + el : el;
    };

    traky.timestamp2ymd = function(timestamp) {
        var date = new Date(timestamp);
        var Y = date.getFullYear();
        var M = date.getMonth() + 1;
        var D = date.getDate();
        var H = date.getHours();
        var I = date.getMinutes();

        if (M < 10) M = '0' + M;
        if (D < 10) D = '0' + D;
        if (H < 10) H = '0' + H;
        if (I < 10) I = '0' + I;

        return Y +'-'+ M +'-'+ D +' '+ H +':'+ I;
    };



    traky.timestamp2hms = function(timestamp) {
        var date = new Date(timestamp);

        var H = date.getHours();
        var I = date.getMinutes();
        var S = date.getSeconds();

        if (H < 10) H = '0' + H;
        if (I < 10) I = '0' + I;
        if (S < 10) S = '0' + S;

        return H +':'+ I + ':' + S;
    };

    traky.ymd2timestamp = function(string) {
        var datetime = string.split(' ');
        var ymd  = datetime[0].split('-');
        var hi   = datetime[1].split(':');
        var date = new Date(ymd[0], (ymd[1] - 1), ymd[2], hi[0], hi[1], 0);
        return date.getTime();
    };

    /**
     * furmato de tiempo
     */

    traky.elapsedTime = function(difference, granularity,format) {

        var ret = '';

        difference = Math.abs(difference);
        difference /= 1000;

        var separator = " ";
        var key_separator = " ";
        var no_key = false;
        var time = 0;

        var periods = [
            {key: "y", t: 31536000},
            {key: "M", t: 2628000},
            {key: "d", t: 86400},
            {key: "h", t: 3600},
            {key: "m", t: 60},
            {key: "s", t: 1}
        ];

        if(format=="nokeys") {
            no_key = true;
            separator = "";
            key_separator = ":";
        }
        else if(format=="smallkeys") {
            separator = "";
            key_separator = ":";
            periods[4].key = "m";
            periods[5].key = "s";
        }

        if (difference < 5) { // less than 5 seconds ago, let's say "just now"
            // ret = "Hace un momento";
            ret = difference;
            return ret;
        } else {
            for (var i in periods) {
                if (difference >= periods[i].t) {
                    time = Math.floor(difference / periods[i].t);
                    if(format=="nokeys" && time<10) time = "0" + time;
                    difference %= periods[i].t;
                    ret += (ret ? key_separator : '') + time + separator;
                    if(!no_key) ret += ((time > 1) ? (periods[i].key === 'mes') ? periods[i].key + '' : periods[i].key + '' : periods[i].key);
                    granularity--;
                }
                if (granularity == '0') {
                    break;
                }
            }
            return ret || '-';
        }
    };

});
