window.UsersListItemView = Backbone.View.extend({
    tagName: 'tr',
  //  template: JST["users/users-list-item.hbs"],

    template: window.JST['users-list-item'],
    Model: window.SiteModel,
    className: 'table-action-buttons',

    events: {
        "click .btn-edit-user": "editUser",
        "click .btn-remove-user": "removeUser"
    },






    removeUser: function() {
        var that = this;
        bootbox.confirm('Seguro que deseas eliminar el usuario?', function(eliminar) {
            if (eliminar) {
                that.model.destroy({
                    success: function() {
                        notify.success('Usuario eliminado', true);
                        that.remove();
                    },
                    error: function() {
                        bootbox.alert('Ha ocurrido un error, por favor, inténtalo de nuevo.');
                    }
                });
            }
        });
    },

    editUser: function() {
     
        app.navigate('admin/users/edit/' + this.model.get('_id'));
        this.userForm = new UserForm({model: this.model});
       $('#main').html(this.userForm.render(this.model.toJSON()).el);
    },

    render: function () {
        var data = this.model.toJSON();
        this.$el.html(this.template(data));
        return this;
    }
});
