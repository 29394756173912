/**
 * @TODO agregar ruta al index (una vez haya algo que meter en el index)
 */
var Route = Backbone.Router.extend({

    routes: {
        '': 'home',

        "admin/users": "users",
      "admin/users/add": "addUser",
      "admin/users/edit/:id": "editUser",


        "admin": "admin"
    },

    admin: function () {
            var mainAdmin = new MainAdminView();
            $('#main').html(mainAdmin.render().el);
        },
    home: function() {
   
    //  app.navigate('admin/users', true);
    },

    initialize: function() {
       

    },


    // -------------------------------------------------------------------------
       // users
       users: function () {
       
           this.usersList = new UsersCollection();
           this.UserListView = new UsersListView({
               collection: this.usersList
           });
           this.usersList.fetch();
           $('#main').html(this.UserListView.render().el);
            traky.setHGroup("hola");
           adminMenuControl();
       },


       addUser: function () {

         
           this.UserForm = new UserForm();
           $('#main').html(this.UserForm.render().el);

    //       traky.users.init();
           adminMenuControl();
       },

       editUser: function (id) {
     
           var that = this;
           this.users = new UsersCollection();

           this.users.fetch({
               success: function (model, response) {
                
                   var user = that.users.get(id);
                   that.UserForm = new UserForm({
                       model: user
                   });
                   $('#main').html(that.UserForm.render(user.toJSON()).el);
                //   traky.users.init();
                   adminMenuControl();
               }
           });
       },

    
    starteo: function() {
    
    //    if (Backbone.history.fragment === '')
      //      app.navigate('#/accounts', true);
    }

});



$(function() {
    window.app = new Route();
//console.log(" creacion de las rutas")
// app.Accounts = new AccountsRouter();

//    app.Sites = new SitesRouter();
  //  app.Streamline = new TrackerStreamlineRouter();
    //app.Events = new EventRouter();

    Backbone.history.start();

    //app.starteo();
});
