window.UsersListView = Backbone.View.extend({

    tagName: 'div',
    className: 'usersList',

    template: JST["users-list"],

/***************************/
//Collection: window.SitesCollection,
//ItemView: window.SitesListItem,
//tagName: 'div',
template: window.JST['users-list'],
cache: true,



/*********************************/


    initialize: function() {
        this.listenTo(this.collection, 'add', this.add);
    },

    add: function(user) {
    
       var userItem = new UsersListItemView({model:user});
        this.$el.find('tbody').append(userItem.render().el);
    },

    render:function () {

        this.$el.html(this.template());
          this.$el.find('#cargando').remove();
        traky.setHGroup('usuarios');
        return this;
    }
});
