var oldSync = Backbone.sync;
Backbone.sync = function(method, model, options) {


    // Global error handler
    options = (options || {});
    options.error = function(xhr) {

        if (xhr.hasOwnProperty('responseJSON')) {
            if (xhr.responseJSON.hasOwnProperty('name')) {
                switch(xhr.responseJSON.name) {
                case 'ValidationError':
                    alert('Error al validar el formulario');
                    break;

                default:

                    // TODO hay que ver si es buena idea delegar los mensajes de
                    // error en la propiedad message, ya y que según como se traten
                    // en el servidor podría devolver mensajes en inglés poco
                    // amigables para el usuario final.
                    if (xhr.responseJSON.hasOwnProperty('message')) {
                      console.log("xhr.responseJSON.message");
                        alert(xhr.responseJSON.message);

                    } else {
                        alert('Error inesperado ('+xhr.statusCode+').');

                    }
                    break;
                }
            } else if (xhr.responseJSON.hasOwnProperty('message')) {
                alert(message);
            } else if (xhr.responseJSON.hasOwnProperty('error')) {
                if (xhr.responseJSON.error.hasOwnProperty('message')) {
                    alert(xhr.responseJSON.error.message);
                }
            }

        } else {

            switch(xhr.statusCode().status) {
            case 404:
                alert('Error 404, lo sentimos, inténtalo de nuevo.');
                break;
            case 400:
                alert('Error al validar el formulario');
                break;
            case 401:
            case 403:
              alert('No tienes permisos para realizar esta acción ('+xhr.statusCode+')');
                break;
            case 500:
                console.log('error-handler:error 500');
            alert('Error inesperado, inténtalo de nuevo');
            }
        }
    };

    return oldSync(method, model, options);
};
