(function(window, $) {

    var traky = (function() {
  
        var self = {};

        self.unloadMessage = false;

        self.config = {
            //accountsurl: '/api/accounts',
            username: '',
            name: '',
            dataTable: {
                pageLength: 50,
                lengthMenu: [
                    [10, 50, 100, -1],
                    [10, 50, 100, "All"]
                ]
            }
        };
        self.action="";
        self.rol_usuario= {
          admin:'all',
          accounts:'all',
          events:'all',
          tools:'all',
          trackers:'all',
          tracks:'all',
          sites:'all',
          users:'all'
        };
        self.rol_admin= {
          admin:'all',
          accounts:'all',
          events:'all',
          sessions:'all',
          events:'all',
          tools:'all',
          trackers:'all',
          tracks:'all',
          sites:'all',
          users:'all'
        };

        self.init = function() {
            if ($(window).width() > 767) {

                $('.navbar-ex1-collapse').each(function() {
                    $(this).removeClass('collapse');
                });
            }



            $(document).trigger('traky_init');
            $(document).on('submit', '#signin', traky.login);
            $(document).on('click', '#logout', traky.logout);
            $(document).on('click', 'a[nolink]', function(e) {
                e.preventDefault();
            });

            $(window).on('beforeunload', function() {
                if (self.unloadMessage === true) {
                    return 'Si sales se perderán los cambios realizados. Quieres continuar?';
                }
            });

            $('form.unloadMessage')
                .on('change', 'input', function() {
                    self.unloadMessage = true;
                })
                .on('change', 'textarea', function() {
                    self.unloadMessage = true;
                })
                .on('change', 'select', function() {
                    self.unloadMessage = true;
                });

        };

        self.inlineEdit = function(e) {

            var that = this;

            e.preventDefault();
            e.stopPropagation();

            var el = $(e.target);
            $(el).disableSelection();

            // revokeEdit permite editar un campo aunque la opcion editable sea false.
            // establecer revokeEdit a true para forzar la edición
            var revokeEdit = false;
            if (el.data('inline-revoke-edit') === true) {
                revokeEdit = true;
            }

            if (el.parent().data('inline-editable') === 'no' && revokeEdit === false) {
                return notify.error('Este campo no es editable (locked)', true);
            }

            var field = el.data('inline-edit');

            var current_value = el.html(); // restore on delete
            var current_real_value = this.model.get(field);
            var value_tpl = current_value.replace(current_real_value, '%s%');

            var data = this.model.toJSON();
            var tplData = {
                name: field,
                value: current_real_value
            };

            // template se encarga de generar el input y botones editar/cancelar
            var input = JST['app/input-td.hbs'](tplData);
            el.empty().append(input);
            el.find('input').select();

            $('html').bind('click', function() {
                el.find('.del').trigger('click');
            });
            el.bind('click', function(e) {
                e.stopPropagation();
            });

            // click boton cerrar
            el.find('.del').click(function() {
                el.unbind('click');
                $('html').unbind('click');
                $(el).empty().append(current_value);
                el.find('input').unbind('keyup');
                $(el).enableSelection();
            });

            // tecla esc en el input
            el.find('input').on('keyup', function(e) {
                if (e.keyCode === 27) {
                    el.find('.del').trigger('click');
                }
            });

            el.find('.val').keydown(function(e) {
                if (e.keyCode === 13) {

                    var dataName = tplData.name.split('.');
                    if (dataName.length > 1) {
                        data[dataName[0]] = {};
                        data[dataName[0]][dataName[1]] = $(this).val();
                    } else {
                        data[tplData.name] = $(this).val();
                    }

                    var new_value = $(this).val();

                    var object = that.model;
                    object.save(data, {
                        success: function(model) {
                            $(el).empty().append(new_value);
                            notify.success('Actualización realizada', true);

                            if (that.afterInlineSave) {
                                that.afterInlineSave(model);
                            }
                        },
                        error: function() {
                            notify.error('error, inténtalo de nuevo');
                        }
                    });
                }
            });

        };

        self.validation = function(model, errors) {
            _.each(errors, function(error) {
                $('#' + error.name).closest('.form-group').addClass('has-error');
            });
        };

        self.validationClear = function(form, input) {
            form = form || 'form';
            if (input) {
                $(form).find(input).closest('.has-error').removeClass('has-error');
            } else {
                $(form).find('.has-error').removeClass('has-error');
            }
        };

        return self;
    })();

    // expose
    window.traky = traky;

})(window, jQuery);

jQuery(function() {
    traky.init();
});
